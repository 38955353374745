import React from "react";

import { graphql } from "gatsby";

import GeschaeftsfuehrungPage from "../unternehmen/geschaeftsfuehrung";

const CompanyManagementPage = (props) => (
    <GeschaeftsfuehrungPage
        tabManagement="Kierownictwo"
        tabProcurators="Prokuratorzy"
        {...props}
    />
);

export default CompanyManagementPage;

export const query = graphql`
    query {
        page: pagesYaml(templateKey: { eq: "pages/zarzad" }) {
            ...Page
            management {
                items {
                    name
                    position
                    company
                    image {
                        src {
                            ...ImageSrc
                            childImageSharp {
                                gatsbyImageData(
                                    layout: FULL_WIDTH
                                    placeholder: BLURRED
                                )
                            }
                        }
                    }
                    break
                }
            }
            procurators {
                items {
                    name
                    position
                    company
                    image {
                        src {
                            ...ImageSrc
                            childImageSharp {
                                gatsbyImageData(
                                    layout: FULL_WIDTH
                                    placeholder: BLURRED
                                )
                            }
                        }
                    }
                    break
                }
            }
        }
    }
`;
